import React from "react"
import { Helmet } from "react-helmet"
import TemplatePage from "../../components/TemplatePage"
import { getAllOferte } from "../../utils/api/oferte"
import TabelActe from "../../components/legislatie/TabelActe"
import { ActNormativ } from "../../utils/api/acteNormative"

export default function OferteTip({ location }) {
  const [items, setItems] = React.useState<ActNormativ[]>([])

  React.useEffect(() => {
    const getItems = async () => {
      try {
        const apiResponse = await getAllOferte()
        if (apiResponse.type) setItems(apiResponse.data)
      } catch (error) {
        console.log(error)
      }
    }
    getItems()
  }, [])

  return (
    <TemplatePage
      location={location}
      title="Oferte tip"
      additionalClassName="templateFullContainerFaqScreen"
    >
      <Helmet>
        <title>Oferte Tip Furnizare - Gazmir</title>
        <meta
          name="description"
          content="Consulta Oferta Tip Gazmir si alege varianta potrivita. Vezi modelul de Contract de Furnizare. Schimba furnizorul actual."
        />
        <meta
          name="keywords"
          content="Oferta gazmir,Gazmir oferta,Gazmir oferta furnizare,oferta furnizare gazmir,Pret furnizare gazmir,Pret gaz gazmir,Gazmir pret gaz,furnizare casnici gazmir,gazmir contract furnizare,model contract furnizare gazmir,model contract gazmir,contract clienti casnici gazmir,contract consumatori casnici gazmir,tarif furnizare gazmir,Tarife gazmir,preturi gazmir,preturi furnizare gazmir,pret final gazmir,tarif gaze gazmir"
        />
      </Helmet>
      <TabelActe items={items} />
    </TemplatePage>
  )
}
